
<script setup>
const props = defineProps({ blok: Object });
// if(propsblok.use_grid){
  const {editing} = await useSettings()
  const { getLayoutClasses } = useLayoutControl();
  const {icons,uiconfig,handleAnimation} = useUIControl()
  const animation_target = ref(null)
onMounted(() => {
  //  console.log(props.blok.layout?.a_enabled && props.blok.layout.animation.custom)
  // if(!process.client)
  handleAnimation(animation_target,{layout:props.blok.layout,type:'section',a:{...props.blok.layout.animation}});
})
const layout_classes = getLayoutClasses(props.blok);
const blok_background = props.blok.content.find((blok) => {
  return blok.is_background;
});
const filtered_bloks = props.blok.content.filter((blok) => !blok.is_background);
const section_id = (props.blok?.id != "" && props.blok.id || '');
// }
</script>

<template>
  <section
    v-if="!blok.disabled || editing"
    v-editable="editing && blok"
    ref="animation_target"
    class="sb-section"
    :class="[{}, blok.variation != '' && blok.variation, layout_classes]"
    :id="section_id"
    :style="{
      color: blok.color_foreground.value,
      'background-color': blok.color_background.value,
    }"
  >
    <div v-if="blok.disabled && editing" class="section-hidden">
      Section is currently disabled and hidden outside editor
    </div>
    <div
      class="sb-section__inner 2xl:container"
      :class="blok.usegrid && 'lg:grid lg:grid-cols-12 gap-x-8'"
    >
      <StoryblokComponent
        v-for="blok in filtered_bloks"
        :key="blok._uid"
        :blok="blok"
      />
    </div>
    <div class="sb-section__background" v-if="blok_background">
      <StoryblokComponent :blok="blok_background" />
    </div>
  </section>
</template>
<style lang="scss">
.sb-section {
  .section-hidden {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: red;
    padding: 1rem;
    color: #fff;
    font-size: 12px;
    border-bottom-right-radius: 10px;
  }
  // margin-bottom:1.75rem;
  // padding:3rem;
}
</style>